import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
    Configure,
    connectAutoComplete,
    InstantSearch,
} from 'react-instantsearch-dom'
import generateMerchantUrl from '../../utils/merchantUrlGenerator'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
    resultOption: {
        color: '#111',
        width: '100%',
    },
    resultOptionFlexContainer: {},
    resultOptionImageWrapper: {
        maxWidth: '60px',
        '& img': {
            display: 'block',
        },
    },
    resultOptionMerchantName: {},
    resultOptionSavingsText: {
        color: '#494949',
        fontSize: '0.9em',
        paddingLeft: '1em',
    },
    autoCompleteInputRoot: {
        padding: '0!important',
        backgroundColor: '#0A3A52',
        cursor: 'pointer',
        maxWidth: '36rem',
        margin: '0 auto',
        borderRadius: '21px',
    },
    autoCompleteInputElement: {
        backgroundColor: '#EBEBEB',
        borderRadius: '20px 0 0 20px',
        fontSize: '16px',
        padding: '12px 10px !important',
        '&::placeholder ': {
            color: '#6A6A6A',
        },
    },
    autoCompleteNotchedOutline: {
        border: 'none',
    },
    searchIcon: {
        color: 'white',
        fontSize: '1.9rem',
        margin: '0 10px 0 6px',
    },
}))

const ResultOption = (merchant, classes, baseUrl) => {
    const merchantUrl = generateMerchantUrl(baseUrl, merchant)
    const isOpenLoop = ['visa', 'mastercard'].includes(merchant.slug)
    const maxDiscountPercentage = parseFloat(merchant.max_discount_percentage)
    const maxCashBackValue = parseFloat(merchant.max_cash_back_value)

    return (
        <Link
            href={merchantUrl}
            className={classes.resultOption}
            underline={'none'}
        >
            <Box
                display="flex"
                alignItems="center"
                width={'100%'}
                lineHeight={1}
                className={classes.resultOptionFlexContainer}
            >
                <Box className={classes.resultOptionImageWrapper}>
                    <img
                        src={merchant.merchantImageUrl}
                        alt={`${merchant.name} Gift Card`}
                        width={'100%'}
                    />
                </Box>
                <Box width={'100%'} paddingLeft={'1em'} fontSize={'0.9em'}>
                    <span className={classes.resultOptionMerchantName}>
                        {merchant.name}
                    </span>
                    <span className={classes.resultOptionSavingsText}>
                        {!isOpenLoop && maxCashBackValue > 0 && (
                            <span>
                                up to ${maxCashBackValue.toFixed(2)} cash back
                            </span>
                        )}
                        {!isOpenLoop && maxDiscountPercentage > 0 && (
                            <span>
                                up to {Math.round(maxDiscountPercentage)}% off
                            </span>
                        )}
                    </span>
                </Box>
            </Box>
        </Link>
    )
}

const navToRefinedBuyPage = (query) => {
    window.location.href = '/buy-gift-cards/?query=' + query
}

const ClickableSearchIcon = ({ currentRefinement }) => {
    const classes = useStyles()

    return (
        <InputAdornment
            position={'end'}
            onClick={(event) => {
                navToRefinedBuyPage(currentRefinement)
            }}
        >
            <SearchIcon className={classes.searchIcon} />
        </InputAdornment>
    )
}

const MaterialUISearchBox = ({
    hits,
    currentRefinement,
    refine,
    baseUrl,
    setIsSearchDropdownOpen,
}) => {
    const classes = useStyles()

    return (
        <Autocomplete
            id={'headerSearchAutocompleteInput'}
            freeSolo // This is here to support the component's use of the Algolia results.
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={'Search for discount gift cards'}
                        variant={'outlined'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <ClickableSearchIcon
                                    currentRefinement={currentRefinement}
                                />
                            ),
                            classes: {
                                root: classes.autoCompleteInputRoot,
                                input: classes.autoCompleteInputElement,
                                notchedOutline:
                                    classes.autoCompleteNotchedOutline,
                            },
                        }}
                    />
                )
            }}
            options={hits}
            getOptionLabel={(option) => option.name || ''}
            onInputChange={(event, searchString) => {
                refine(searchString)
            }}
            onFocus={() => setIsSearchDropdownOpen(true)}
            onBlur={() => setIsSearchDropdownOpen(false)}
            onChange={(event, currentValue, reason, changeDetails) => {
                if (event.key === 'Enter') {
                    event.preventDefault()
                    navToRefinedBuyPage(currentRefinement)
                }
            }}
            renderOption={(merchant, optionState) => {
                return ResultOption(merchant, classes, baseUrl)
            }}
        />
    )
}

const CustomAutoComplete = connectAutoComplete(MaterialUISearchBox)

const HeaderSearchComponent = ({ baseUrl, setIsSearchDropdownOpen }) => {
    const algoliaApplicationId = window.React.algolia_application_id
    const algoliaSearchApiKey = window.React.algolia_search_api_key
    const algoliaMerchantIndex = window.React.algolia_merchant_index

    const algoliaClient = algoliasearch(
        algoliaApplicationId,
        algoliaSearchApiKey
    )
    const searchClient = {
        search(requests) {
            if (requests.every(({ params }) => !params.query)) {
                return Promise.resolve({
                    results: requests.map(() => ({
                        hits: [],
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0,
                    })),
                })
            }

            return algoliaClient.search(requests)
        },
    }

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={algoliaMerchantIndex}
            onSearchStateChange={(searchState) => {}}
        >
            <CustomAutoComplete
                baseUrl={baseUrl}
                setIsSearchDropdownOpen={setIsSearchDropdownOpen}
            />
            <Configure
                hitsPerPage={4}
                filters={'local:false AND enabled:true'}
            />
        </InstantSearch>
    )
}

export default HeaderSearchComponent
