import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    hideOnSmallAndDown: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
}))

const HideOnSmallAndDown = ({ children }) => {
    const classes = useStyles()

    return <Box className={classes.hideOnSmallAndDown}>{children}</Box>
}

export default HideOnSmallAndDown
