import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import isNull from 'lodash/isNull'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { pushToDataLayer } from '../../../utils/dataLayer'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import HideOnMediumAndUp from './HideOnMediumAndUp'
import HideOnSmallAndDown from './HideOnSmallAndDown'
import { withStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    promoBanner: {
        promotionBanner: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'center',
            },
        },
    },
    promoBannerContainer: {
        backgroundColor: '#0A3A52',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgba(244, 244, 244, 1)',
        padding: '5px',
        flexDirection: 'column',
        gap: 8,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            maxHeight: '62px',
            padding: '15px 0',
        },
    },
    promoBannerText: {
        fontSize: 13,
        padding: 0,
        margin: 0,
        color: 'white',
        textAlign: 'center',
        maxWidth: 250,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            maxWidth: '100%',
        },
    },
    promoBannerButton: {
        color: 'white!important',
        all: 'unset',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: '10px',
        fontSize: 14,
    },
    promoBannerContents: {
        display: 'flex',
        gap: 8,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
    },
    promoBannerNavigationButton: {
        background: 'transparent',
        color: 'white',
        border: '1px solid transparent',
        cursor: 'pointer',
        padding: 0,
        position: 'relative',
        top: 3,
        [theme.breakpoints.up('md')]: {
            padding: '0 .5rem',
            marginRight: 25,
        },
    },
    promoBannerHref: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'none',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    infoIcon: {
        width: 18,
        position: 'relative',
        top: 3.5,
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            width: 25,
            position: 'relative',
            top: 2.75,
        },
    },
    terms: {
        fontSize: 14,
        textAlign: 'center',
        paddingBottom: 5,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
    },
    navButtonSpacer: {
        [theme.breakpoints.up('md')]: {
            minWidth: 10,
        },
    },
    promoBannerNavigation: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
}))

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip)

const PromoBannerCarousel = (props) => {
    const { promoBanners } = props

    const [promoBanner, setPromoBanner] = useState(promoBanners[0])
    const [shouldShowTerms, setShouldShowTerms] = useState(false)

    const classes = useStyles()

    const showNextBanner = () => {
        const currentIndex = promoBanners.indexOf(promoBanner)
        const newIndex = (currentIndex + 1) % promoBanners.length
        setPromoBanner(promoBanners[newIndex])
    }

    const renderHtmlContent = (bannerHtml) => {
        return bannerHtml ? (
            <Box
                id={'promotion-banner'}
                data-testid={'promotion-banner-html'}
                dangerouslySetInnerHTML={{
                    __html: bannerHtml,
                }}
            />
        ) : null
    }

    const renderBannerContent = (promoBanner) => {
        if (!isNull(promoBanner.bannerHtml)) {
            return renderHtmlContent(promoBanner.bannerHtml)
        } else {
            return renderPromoCodeContent(promoBanner, '')
        }
    }

    const renderPromoCodeContent = (promoBanner, bannerImg) => {
        const sendPromoClickEvent = () => {
            pushToDataLayer([
                'PromotionBannerClick',
                'Promotion Banner',
                'Click',
                promoBanner.id,
            ])
        }

        return (
            <div className={classes.promoBannerContainer}>
                <div className={classes.promoBannerContents}>
                    <a
                        className={classes.promoBannerHref}
                        href={promoBanner.landingPageUrl}
                        onClick={sendPromoClickEvent}
                    >
                        {promoBanner.bannerText && (
                            <p className={classes.promoBannerText}>
                                {promoBanner.bannerText}
                            </p>
                        )}
                    </a>
                </div>

                <div className={classes.promoBannerNavigation}>
                    {promoBanner.buttonText && (
                        <a
                            className={classes.promoBannerHref}
                            href={promoBanner.landingPageUrl}
                            onClick={sendPromoClickEvent}
                        >
                            <div className={classes.promoBannerButton}>
                                {promoBanner.buttonText}
                            </div>
                        </a>
                    )}

                    <div className={classes.navButtonSpacer}>
                        {promoBanners.length > 1 && (
                            <button
                                className={classes.promoBannerNavigationButton}
                                onClick={showNextBanner}
                            >
                                <ChevronRightIcon
                                    style={{ fontSize: '1.5rem' }}
                                />
                            </button>
                        )}
                    </div>

                    <HideOnSmallAndDown>
                        <LightTooltip title={promoBanner.terms}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                        </LightTooltip>
                    </HideOnSmallAndDown>
                    <HideOnMediumAndUp>
                        <InfoOutlinedIcon
                            className={classes.infoIcon}
                            onClick={() => {
                                setShouldShowTerms((prevState) => !prevState)
                            }}
                        />
                    </HideOnMediumAndUp>
                </div>

                {shouldShowTerms && (
                    <div className={classes.terms}>{promoBanner.terms}</div>
                )}
            </div>
        )
    }

    return (
        <div className={classes.promoBanner}>
            {renderBannerContent(promoBanner)}
        </div>
    )
}

export default PromoBannerCarousel
