export const ActivateCardIcon = () => (
    <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <g clip-path="url(#clip0_9224_14068)">
                <path
                    d="M18.5865 7.76221H4.97795C4.21087 7.76221 3.61426 8.38723 3.61426 9.1259V19.1263C3.61426 19.8934 4.21087 20.49 4.97795 20.49H18.5865C19.3536 20.49 19.9786 19.8934 19.9786 19.1263V9.1259C19.9786 8.38723 19.3536 7.76221 18.5865 7.76221ZM5.14841 9.1259H18.416C18.5297 9.1259 18.5865 9.21113 18.5865 9.29636V10.4896H4.97795V9.29636C4.97795 9.21113 5.03477 9.1259 5.14841 9.1259ZM18.416 19.1263H5.14841C5.03477 19.1263 4.97795 19.0695 4.97795 18.9559V14.1261H18.5865V18.9559C18.5865 19.0695 18.5297 19.1263 18.416 19.1263ZM9.06903 16.2853C9.06903 16.1148 8.89857 15.9444 8.72811 15.9444H6.68257C6.4837 15.9444 6.34164 16.1148 6.34164 16.2853V17.4217C6.34164 17.6206 6.4837 17.7626 6.68257 17.7626H8.72811C8.89857 17.7626 9.06903 17.6206 9.06903 17.4217V16.2853ZM14.5238 16.2853C14.5238 16.1148 14.3533 15.9444 14.1829 15.9444H10.3191C10.1202 15.9444 9.97816 16.1148 9.97816 16.2853V17.4217C9.97816 17.6206 10.1202 17.7626 10.3191 17.7626H14.1829C14.3533 17.7626 14.5238 17.6206 14.5238 17.4217V16.2853Z"
                    fill="#333333"
                />
                <circle cx="19.1815" cy="9.03156" r="5.09455" fill="#EEEEEE" />
                <path
                    d="M19.182 4.69434C16.786 4.69434 14.8447 6.6531 14.8447 9.03159C14.8447 11.4276 16.786 13.3688 19.182 13.3688C21.5605 13.3688 23.5192 11.4276 23.5192 9.03159C23.5192 6.6531 21.5605 4.69434 19.182 4.69434ZM19.182 5.5338C21.1058 5.5338 22.6798 7.10781 22.6798 9.03159C22.6798 10.9729 21.1058 12.5294 19.182 12.5294C17.2407 12.5294 15.6842 10.9729 15.6842 9.03159C15.6842 7.10781 17.2407 5.5338 19.182 5.5338ZM21.6304 7.82485L21.2282 7.42261C21.1582 7.33516 21.0183 7.33516 20.9309 7.42261L18.4649 9.87106L17.4156 8.82172C17.3282 8.73428 17.2057 8.73428 17.1183 8.82172L16.716 9.20648C16.6461 9.29392 16.6461 9.43383 16.716 9.50379L18.3075 11.1128C18.395 11.2002 18.5174 11.2002 18.6048 11.1128L21.6304 8.12217C21.7004 8.03472 21.7004 7.89481 21.6304 7.82485Z"
                    fill="#333333"
                />
            </g>
            <defs>
                <clipPath id="clip0_9224_14068">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.276367 0.48999)"
                    />
                </clipPath>
            </defs>
        </svg>
    </div>
)
