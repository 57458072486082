const MobileMenuTextIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
    >
        <path
            d="M6 13.5928C6 13.0615 5.53125 12.5928 5 12.5928C4.4375 12.5928 4 13.0615 4 13.5928C4 14.1553 4.4375 14.5928 5 14.5928C5.53125 14.5928 6 14.1553 6 13.5928ZM10 2.09277C10 1.28027 9.3125 0.592773 8.5 0.592773H1.5C0.65625 0.592773 0 1.28027 0 2.09277V15.0928C0 15.9365 0.65625 16.5928 1.5 16.5928H8.5C9.3125 16.5928 10 15.9365 10 15.0928V2.09277ZM8.5 14.9053C8.5 15.0303 8.40625 15.0928 8.3125 15.0928H1.6875C1.5625 15.0928 1.5 15.0303 1.5 14.9053V2.28027C1.5 2.18652 1.5625 2.09277 1.6875 2.09277H8.3125C8.40625 2.09277 8.5 2.18652 8.5 2.28027V14.9053Z"
            fill="#333333"
        />
    </svg>
)
export default MobileMenuTextIcon
