export const CheckBalanceIcon = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                d="M10.7767 14.99H10.7455C10.7455 14.49 10.808 14.74 8.08925 9.33374C7.83925 8.77124 7.308 8.48999 6.7455 8.48999C6.21425 8.48999 5.683 8.77124 5.433 9.33374C2.683 14.7712 2.77675 14.5212 2.77675 14.99C2.77675 16.3962 4.558 17.49 6.77675 17.49C8.96425 17.49 10.7767 16.3962 10.7767 14.99ZM5.3705 12.8025C5.71425 12.0837 6.183 11.1775 6.7455 10.0212V9.98999H6.77675C7.3705 11.24 7.8705 12.1775 8.21425 12.8962C8.558 13.615 8.83925 14.1462 9.02675 14.49H4.4955C4.71425 14.115 4.9955 13.5525 5.3705 12.8025ZM19.2767 18.99H13.5267V9.36499C14.3705 9.11499 15.0267 8.39624 15.2142 7.48999H19.2767C19.5267 7.48999 19.7767 7.27124 19.7767 6.98999V6.48999C19.7767 6.23999 19.5267 5.98999 19.2767 5.98999H15.058C14.6517 5.11499 13.7767 4.48999 12.7767 4.48999C11.7455 4.48999 10.8705 5.11499 10.4642 5.98999H6.27675C5.9955 5.98999 5.77675 6.23999 5.77675 6.48999V6.98999C5.77675 7.27124 5.9955 7.48999 6.27675 7.48999H10.308C10.4955 8.39624 11.1517 9.11499 12.0267 9.36499V18.99H6.27675C5.9955 18.99 5.77675 19.24 5.77675 19.49V19.99C5.77675 20.2712 5.9955 20.49 6.27675 20.49H19.2767C19.5267 20.49 19.7767 20.2712 19.7767 19.99V19.49C19.7767 19.24 19.5267 18.99 19.2767 18.99ZM12.7767 7.98999C12.2142 7.98999 11.7767 7.55249 11.7767 6.98999C11.7767 6.45874 12.2142 5.98999 12.7767 5.98999C13.308 5.98999 13.7767 6.45874 13.7767 6.98999C13.7767 7.55249 13.308 7.98999 12.7767 7.98999ZM22.7455 14.99C22.7455 14.49 22.808 14.74 20.0892 9.33374C19.8392 8.77124 19.308 8.48999 18.7455 8.48999C18.2142 8.48999 17.683 8.77124 17.433 9.33374C14.683 14.7712 14.7767 14.5212 14.7767 14.99C14.7767 16.3962 16.558 17.49 18.7767 17.49C20.9642 17.49 22.7767 16.3962 22.7767 14.99H22.7455ZM16.4955 14.49C16.7142 14.115 16.9955 13.5525 17.3705 12.8025C17.7142 12.0837 18.183 11.1775 18.7455 10.0212V9.98999H18.7767C19.3705 11.24 19.8705 12.1775 20.2142 12.8962C20.558 13.615 20.8392 14.1462 21.0267 14.49H16.4955Z"
                fill="#333333"
            />
        </svg>
    </div>
)
