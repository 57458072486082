import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import get from 'lodash/get'

function gcgPointsToCashBack(points) {
    const gcgPointsToCashPercentage = 0.5
    return (
        '$' +
        (
            Math.floor(points * (gcgPointsToCashPercentage / 100) * 100) / 100
        ).toFixed(2)
    )
}

const getFormattedDate = (dateString, includeTime = false) => {
    const date = new Date(dateString)
    let timeString =
        (date.getMonth() > 8
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1)) +
        '/' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        '/' +
        date.getFullYear()
    if (includeTime) {
        //Include the time, which will be in the format HH:MM am/pm
        timeString +=
            ', ' +
            date
                .toLocaleTimeString()
                .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')
    }
    return timeString
}

const diffInDays = (date1, date2) => {
    const diffInMs = date1 - date2
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24))
}

const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
]

const unitedStates = {
    Alabama: 'AL',
    Alaska: 'AK',
    'American Forces Americas': 'AA',
    'American Forces Europe': 'AE',
    'American Forces Pacific': 'AP',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District of Columbia': 'DC',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
}

function mapStateAbbreviations(state) {
    return unitedStates[state] ? unitedStates[state] : state
}

const states = Object.keys(unitedStates).map((key) => ({
    code: unitedStates[key],
    label: key,
}))

/**
 * Get the state that corresponds with the given state abbreviation
 * @param abbr
 * @returns {{code: *, label: *}|null}
 */
const getStateFromAbbreviation = (abbr) => {
    return states.find((state) => state.code === abbr) || null
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const formatAmount = (amount) => parseFloat(amount).toFixed(2)

const getCartItemProductImage = (cartItem) => {
    console.log('getCartItemProductImage...', {cartItem})
    const {
        open_loop: isOpenLoop,
        custom_card_image: customCardImage,
        on_demand_gift_template: onDemandGiftTemplate,
        merchant,
    } = cartItem

    if (isOpenLoop) {
        return customCardImage
    } else if (onDemandGiftTemplate) {
        return onDemandGiftTemplate.image_url
    } else {
        return merchant.logo_url
    }
}
const getCartItemProductGiftDetails = (cartItem, deliveryGroup) => {
    const {
        delivery_group_uuid: deliveryGroupUuid,
        custom_card_details: customCardDetails,
        plastic: isPlastic,
        gift: isGift,
        gyp_gift: gypGift,
        open_loop: isOpenLoop,
        shipping,
        gift_recipient_name: giftRecipientName,
        gift_recipient_email: giftRecipientEmail,
    } = cartItem

    if (isPlastic) {
        let shippingInfo
        if (deliveryGroupUuid) {
            if (deliveryGroup.bulk_shipping_address) {
                // Bulk delivery groups - gets shipping info off deliveryGroup's bulk_shipping_name & bulk_shipping_address object
                const {
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zip_code: zipCode,
                } = deliveryGroup.bulk_shipping_address

                shippingInfo = {
                    name: deliveryGroup.bulk_shipping_name,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode,
                }
            } else {
                // Individual delivery groups - gets shipping info off cartItem's custom_card_details object
                const {
                    shipping_name: name,
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    city,
                    state,
                    zip_code: zipCode,
                } = customCardDetails

                shippingInfo = {
                    name,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode,
                }
            }
        } else {
            // Legacy - gets shipping info off cartItem's shipping object
            const {
                name,
                address_line1: addressLine1,
                address_line2: addressLine2,
                city,
                state,
                zip_code: zipCode,
            } = shipping

            shippingInfo = {
                name,
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
            }
        }

        return !isEmpty(shippingInfo.addressLine2)
            ? `${shippingInfo.name}, ${shippingInfo.addressLine1}, ${shippingInfo.addressLine2}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.zipCode}`
            : `${shippingInfo.name}, ${shippingInfo.addressLine1}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.zipCode}`
    } else {
        return isGift || isOpenLoop || gypGift
            ? `${giftRecipientName}, ${giftRecipientEmail}`
            : `Myself, sent by email`
    }
}

const getCartItemProductGiftRecipientName = (cartItem, deliveryGroup) => {
    const {
        delivery_group_uuid: deliveryGroupUuid,
        custom_card_details: customCardDetails,
        plastic: isPlastic,
        gift: isGift,
        gyp_gift: gypGift,
        open_loop: isOpenLoop,
        shipping,
        gift_recipient_name: giftRecipientName,
        gift_recipient_email: giftRecipientEmail,
    } = cartItem

    if (isPlastic) {
        let shippingInfo
        if (deliveryGroupUuid) {
            if (deliveryGroup.bulk_shipping_address) {
                return deliveryGroup.bulk_shipping_name
            } else {
                return customCardDetails.shipping_name
            }
        } else {
            return shipping.name
        }
    } else {
        return isGift || gypGift || isOpenLoop  ? giftRecipientName : `Myself`
    }
}
const getCartItemProductGiftAddress = (cartItem, deliveryGroup) => {
    const {
        delivery_group_uuid: deliveryGroupUuid,
        custom_card_details: customCardDetails,
        plastic: isPlastic,
        gyp_gift: gypGift,
        gift: isGift,
        open_loop: isOpenLoop,
        shipping,
        gift_recipient_name: giftRecipientName,
        gift_recipient_email: giftRecipientEmail,
    } = cartItem

    if (isPlastic) {
        let shippingInfo
        if (deliveryGroupUuid) {
            if (deliveryGroup.bulk_shipping_address) {
                // Bulk delivery groups - gets shipping info off deliveryGroup's bulk_shipping_name & bulk_shipping_address object
                const {
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zip_code: zipCode,
                } = deliveryGroup.bulk_shipping_address

                shippingInfo = {
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode,
                }
            } else {
                // Individual delivery groups - gets shipping info off cartItem's custom_card_details object
                const {
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    city,
                    state,
                    zip_code: zipCode,
                } = customCardDetails

                shippingInfo = {
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode,
                }
            }
        } else {
            // Legacy - gets shipping info off cartItem's shipping object
            const {
                address_line1: addressLine1,
                address_line2: addressLine2,
                city,
                state,
                zip_code: zipCode,
            } = shipping

            shippingInfo = {
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
            }
        }

        return !isEmpty(shippingInfo.addressLine2)
            ? `${shippingInfo.addressLine1}, ${shippingInfo.addressLine2}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.zipCode}`
            : `${shippingInfo.addressLine1}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.zipCode}`
    } else {
        return isGift || gypGift || isOpenLoop ? giftRecipientEmail : `sent by email`
    }
}
const getCardShippingMethod = (card) => {
    if (has(card, 'deliveryInfo')) {
        return get(card.deliveryInfo, 'shippingMethod')
    } else {
        return get(card, 'shippingMethod')
    }
}

const getIndividualPlasticCardsByShippingMethod = (individualPlasticCards) => {
    const shippingMethodGroups = []

    individualPlasticCards.forEach((currentCard) => {
        const currentCardShippingMethod = getCardShippingMethod(currentCard)

        // Skip cards that have already been added to a shippingMethodGroup
        const shippingMethodGroupAlreadyContainsCard = (
            shippingMethodGroup,
            index
        ) => {
            const shippingMethod = getCardShippingMethod(
                shippingMethodGroup.cards[index]
            )
            return currentCardShippingMethod === shippingMethod
        }

        if (shippingMethodGroups.some(shippingMethodGroupAlreadyContainsCard))
            return

        // Filter all cards by currentCard's shippingMethod and add them all to a shippingMethodGroup
        shippingMethodGroups.push({
            id: currentCardShippingMethod,
            cards: individualPlasticCards.filter((filterByCard) => {
                const shippingMethod = getCardShippingMethod(filterByCard)

                return currentCardShippingMethod === shippingMethod
            }),
        })
    })

    return shippingMethodGroups
}

const getCurrentFedExShippingMethod = (shippingMethodId, shippingMethods) =>
    shippingMethods.find(
        (s) =>
            s.name.toLowerCase().includes('fedex') &&
            `${s.id}` === `${shippingMethodId}`
    )

const maskPhone = (value = null) => {
    if (!value) {
        return ''
    }
    value = value.replace(/\D/g, '').replace(/^1/, '')

    let formattedValue = ''
    const length = value.length

    if (length === 0) {
        formattedValue = ''
    }

    if (length > 0 && length <= 3) {
        formattedValue = `(${value.substring(0, 3)}`
    }

    if (length > 3 && length <= 6) {
        formattedValue = `(${value.substring(0, 3)}) ${value.substring(3)}`
    }

    if (length > 6) {
        formattedValue = `(${value.substring(0, 3)}) ${value.substring(
            3,
            6
        )}-${value.substring(6, 10)}`
    }
    return formattedValue
}

function getCookie(name) {
    const cookieName = `${encodeURIComponent(name)}=`;
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.startsWith(cookieName)) {
            return decodeURIComponent(cookie.substring(cookieName.length));
        }
    }

    return null;
}


export {
    gcgPointsToCashBack,
    getFormattedDate,
    diffInDays,
    mapStateAbbreviations,
    alphabet,
    unitedStates,
    capitalizeFirstLetter,
    formatAmount,
    getCartItemProductImage,
    getCartItemProductGiftDetails,
    getCartItemProductGiftRecipientName,
    getCartItemProductGiftAddress,
    getIndividualPlasticCardsByShippingMethod,
    getCurrentFedExShippingMethod,
    getStateFromAbbreviation,
    states,
    maskPhone,
    getCookie
}
