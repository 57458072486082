export const LAZY_IMAGE_SRC =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
export const EGIFT_TYPE = 'egift'
export const VIRTUAL_TYPE = 'virtual'
export const PLASTIC_TYPE = 'plastic'
export const GYP_TYPE = 'gyp'
export const GTM_LAST_IMPRESSION_CLICKED = 'gtm_last_impression_clicked'
export const SMB_CART_MAX_TOTAL = 10000
export const SMB_CART_MAX_QUANTITY = 50
export const MIN_OPEN_LOOP_LOAD_VALUE = 10
export const MAX_OPEN_LOOP_LOAD_VALUE = 500
export const MIN_CARD_QUANTITY = 1
export const MAX_CARD_QUANTITY = 20
export const ORDER_TOTAL_LIMIT = 2500
export const MASTERCARD = 'mastercard'
export const VISA = 'visa'
export const OPEN_LOOP_BRAND_SLUGS = [VISA, MASTERCARD]
export const SMB_APPLICATION_SOURCE = {
    KYC_FORM: 'kyc_form',
    ORDER_FLOW: 'order_flow',
}
export const OPEN_LOOP_CARD_FEES_TABLE_DATA = [
    {
        cardValue: '$10.00 - $74.99',
        cardFee: '$2.95',
        usageFee: '$0',
        low: 10,
        high: 74.99,
        fee: 2.95,
    },
    {
        cardValue: '$75.00 - $149.99',
        cardFee: '$3.95',
        usageFee: '$0',
        low: 75,
        high: 149.99,
        fee: 3.95,
    },
    {
        cardValue: '$150.00 - $249.99',
        cardFee: '$4.95',
        usageFee: '$0',
        low: 150,
        high: 249.99,
        fee: 4.95,
    },
    {
        cardValue: '$250.00 - $349.99',
        cardFee: '$5.95',
        usageFee: '$0',
        low: 250,
        high: 349.99,
        fee: 5.95,
    },
    {
        cardValue: '$350.00 - $500.00',
        cardFee: '$6.95',
        usageFee: '$0',
        low: 350,
        high: 500,
        fee: 6.95,
    },
]

// Cloudinary Values
export const CLOUDINARY_COBRAND_PRESET = 'o5hjbdvw'
export const CLOUDINARY_NAME = 'gift-card-granny'
export const CLOUDINARY_IMAGE_UPLOAD_URL =
    'https://api.cloudinary.com/v1_1/gift-card-granny/image/upload'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729716180/GCG/card_overlays/Visa_Reward_lines3-4_pr6u89.png'
export const CLOUDINARY_COBRAND_MASTERCARD_OVERLAY_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1669214381/GCG/smb_assets/reward-mc_g7iyk6.png'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_WITH_YOUR_LOGO_HERE =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729716180/GCG/card_overlays/Visa_Reward_YLH_lines3-4_v4ac3w.png'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_WITHOUT_NAME_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729778282/GCG/card_overlays/Visa_Reward_Number_Lines3-4_vr6mfg.png'
export const CLOUDINARY_COBRAND_MASTERCARD_OVERLAY_WITHOUT_NAME_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1700537926/GCG/smb_assets/reward-mc-no-emboss-name-mljrzru6x53fpx7bf1ix.png'

/*
    Card Builder Values
    - card is 3.375in x 2.125in
    - c80 card image dimensions 2.13" x 3.38"
    - 324 pixels x 204 pixels
    - width for 300dpi calc = .9 * 3.375 = 3.0375
    - height = .4 * 2.125 = .85
    - = 911 x 255 pix <-- magic numbers to determine how large the logo needs to be
    a full art card image needs to be at least = 1013 x 638 pix
*/
export const DEFAULT_DESCRIPTOR_COLOR = 'white'
export const DEFAULT_COBRAND_DESCRIPTOR_TEXT = 'REWARD CARD'
export const CARD_PROPORTION = 1.5625 // Proportion of width to height.
export const CARD_SCALE = 2
export const CARD_WIDTH = 325
export const CARD_HEIGHT = CARD_WIDTH / CARD_PROPORTION

export const CARD_WIDTH_DEFAULT = CARD_WIDTH * CARD_SCALE
export const CARD_HEIGHT_DEFAULT = CARD_HEIGHT * CARD_SCALE

export const PRINT_QUALITY_WIDTH = 2100 // px
export const PRINT_QUALITY_HEIGHT = PRINT_QUALITY_WIDTH / CARD_PROPORTION
const PRINT_QUALITY_GUTTER_X = 38 // px
const PRINT_QUALITY_GUTTER_Y = 35 // px
export const PRINT_QUALITY_WIDTH_SCALE =
    PRINT_QUALITY_WIDTH / CARD_WIDTH_DEFAULT
export const PRINT_QUALITY_HEIGHT_SCALE =
    PRINT_QUALITY_HEIGHT / CARD_HEIGHT_DEFAULT

export const LOGO_WIDTH_DEFAULT = CARD_WIDTH * 0.9 * CARD_SCALE
export const LOGO_HEIGHT_DEFAULT = CARD_HEIGHT * 0.1 * CARD_SCALE
export const DEFAULT_LOAD_VALUE = 50

export const BASE_PAD_PERCENT_FROM_EDGE = 0.05
export const PAD_PERCENT_FROM_EDGE_X =
    BASE_PAD_PERCENT_FROM_EDGE + PRINT_QUALITY_GUTTER_X / PRINT_QUALITY_WIDTH
export const PAD_PERCENT_FROM_EDGE_Y =
    BASE_PAD_PERCENT_FROM_EDGE + PRINT_QUALITY_GUTTER_Y / PRINT_QUALITY_HEIGHT

export const CARD_DATE = `${String(new Date().getMonth() + 1).padStart(
    2,
    '0'
)}/${String(new Date().getFullYear() + 7).substr(-2)}`

// Balance check page
export const FILTERED_MERCHANT_CHUNK_REQUEST = 'filtered-merchant-chunk-request'
export const balanceCheckMerchantChunks = [
    'CDE',
    'FGH',
    'IJK',
    'LMN',
    'OPQ',
    'RST',
    'UVW',
    'XYZ',
]

export const FEATURED_MERCHANTS_STORAGE_PATH =
    'gcg_on_demand_featured_merchants1'
export const CONSUMER_CART_SOURCE = 'gcg_web'

export const ORDER_LIMIT_EXCEEDED = 'order_limit_exceeded'
export const GIFT_LIMIT_EXCEEDED = 'gift_limit_exceeded'
export const GIFT_RECIPIENT_ORDER_LIMIT_EXCEEDED =
    'gift_recipient_order_limit_exceeded'

export const STANDARD_CARRIER = 'standard_carrier'
export const GREETING_CARD_CARRIER = 'greeting_card_carrier'
export const GREETING_CARD_HANDWRYTTEN_CARRIER =
    'greeting_card_handwrytten_carrier'
export const PERSONALIZE_GREETING_CARD_FORM = 'personalize_greeting_card_form'
export const SHIPPING_GREETING_CARD_FORM = 'shipping_greeting_card_form'

export const AVAILABLE_CARD_NETWORKS = [
    {
        name: 'Visa',
        logo: 'https://marketingcdn.giftcardgranny.com/Logos/visa_bug.svg',
    },
    {
        name: 'Mastercard',
        logo: 'https://marketingcdn.giftcardgranny.com/Logos/mc_bug.svg',
    },
]

export const CARD_NETWORK = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
}

export const CARD_TYPE = {
    PLASTIC: 'plastic',
    VIRTUAL: 'virtual',
}

export const CARRIER_TYPE = {
    PAPER_CARRIER: 'paper_carrier',
    GREETING_CARD: 'greeting_card',
    GREETING_CARD_HANDWRYTTEN: 'greeting_card_handwrytten',
    NO_CARRIER: 'no_carrier',
}

export const CARD_STYLE = {
    UPLOAD_IMAGE: 'upload_image',
    PREDESIGNED: 'predesigned',
}

export const DELIVERY_STYLE = {
    BULK_SHIP: 'bulk_ship',
    INDIVIDUAL_SHIP: 'individual_ship',
    INDIVIDUAL_EMAIL: 'email',
    SPREADSHEET: 'download',
}
export const GROUP_SHIPMENT_DECISION = {
    YES: 'yes',
    NO: 'no',
}

export const CLOUDINARY_TRANSFORMATION_FAKE_EMBOSS_VISA =
    't_visa_emboss_preview'
export const CLOUDINARY_TRANSFORMATION_FAKE_EMBOSS_MC = 't_mc_emboss_preview'

export const CLOUDINARY_TRANSFORMATION_WITHOUT_EMBOSS_VISA =
    't_consumer_visa_without_emboss_preview_20241024'
export const CLOUDINARY_TRANSFORMATION_WITHOUT_EMBOSS_MC =
    't_consumer_mc_without_emboss_preview'

export const DEFAULT_BAD_WORDS_HELPER_TEXT =
    'Contains words or phrases that are not permitted.'

export const DEFAULT_FORM_ERROR_HELPER_TEXT = 'Error.'

export const MAX_CANVAS_PIXELS = 16777216

export const MASKED_PHONE_NUMBER_CHAR_COUNT = 14

export const states = [
    { abbreviation: 'AL', name: 'Alabama' },
    { abbreviation: 'AK', name: 'Alaska' },
    { abbreviation: 'AZ', name: 'Arizona' },
    { abbreviation: 'AR', name: 'Arkansas' },
    { abbreviation: 'CA', name: 'California' },
    { abbreviation: 'CO', name: 'Colorado' },
    { abbreviation: 'CT', name: 'Connecticut' },
    { abbreviation: 'DE', name: 'Delaware' },
    { abbreviation: 'DC', name: 'District of Columbia' },
    { abbreviation: 'FL', name: 'Florida' },
    { abbreviation: 'GA', name: 'Georgia' },
    { abbreviation: 'HI', name: 'Hawaii' },
    { abbreviation: 'ID', name: 'Idaho' },
    { abbreviation: 'IL', name: 'Illinois' },
    { abbreviation: 'IN', name: 'Indiana' },
    { abbreviation: 'IA', name: 'Iowa' },
    { abbreviation: 'KS', name: 'Kansas' },
    { abbreviation: 'KY', name: 'Kentucky' },
    { abbreviation: 'LA', name: 'Louisiana' },
    { abbreviation: 'ME', name: 'Maine' },
    { abbreviation: 'MD', name: 'Maryland' },
    { abbreviation: 'MA', name: 'Massachusetts' },
    { abbreviation: 'MI', name: 'Michigan' },
    { abbreviation: 'MN', name: 'Minnesota' },
    { abbreviation: 'MS', name: 'Mississippi' },
    { abbreviation: 'MO', name: 'Missouri' },
    { abbreviation: 'MT', name: 'Montana' },
    { abbreviation: 'NE', name: 'Nebraska' },
    { abbreviation: 'NV', name: 'Nevada' },
    { abbreviation: 'NH', name: 'New Hampshire' },
    { abbreviation: 'NJ', name: 'New Jersey' },
    { abbreviation: 'NM', name: 'New Mexico' },
    { abbreviation: 'NY', name: 'New York' },
    { abbreviation: 'NC', name: 'North Carolina' },
    { abbreviation: 'ND', name: 'North Dakota' },
    { abbreviation: 'OH', name: 'Ohio' },
    { abbreviation: 'OK', name: 'Oklahoma' },
    { abbreviation: 'OR', name: 'Oregon' },
    { abbreviation: 'PA', name: 'Pennsylvania' },
    { abbreviation: 'RI', name: 'Rhode Island' },
    { abbreviation: 'SC', name: 'South Carolina' },
    { abbreviation: 'SD', name: 'South Dakota' },
    { abbreviation: 'TN', name: 'Tennessee' },
    { abbreviation: 'TX', name: 'Texas' },
    { abbreviation: 'UT', name: 'Utah' },
    { abbreviation: 'VT', name: 'Vermont' },
    { abbreviation: 'VA', name: 'Virginia' },
    { abbreviation: 'WA', name: 'Washington' },
    { abbreviation: 'WV', name: 'West Virginia' },
    { abbreviation: 'WI', name: 'Wisconsin' },
    { abbreviation: 'WY', name: 'Wyoming' },
]
