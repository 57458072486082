import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    hideOnMediumAndUp: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
}))

const HideOnMediumAndUp = ({ children, className }) => {
    const classes = useStyles()

    return (
        <Box className={`${classes.hideOnMediumAndUp} ${className}`}>
            {children}
        </Box>
    )
}

export default HideOnMediumAndUp
